import React from "react";
import { useNavigate } from "react-router-dom";
import fluxLogo from './../../assets/img/fluxlogo.png';
import { ethers } from "ethers";

import "./home.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Home = ({ account, setAccount }) => {
  let navigate = useNavigate();

  let isConnected = Boolean(account[0]);

  if (isConnected) {
    navigate("/dashboard/incio");
  }

  async function initConnection() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccount(accounts);
      navigate("/dashboard/incio");
      console.log("account ", account);
    }
  }

  // regresar a app @muñiz
  return (
    <React.Fragment>
      <header className="header">
        <nav className="header__nav">
        <div className="header__nav--left">
    <a href="#" className="header__nav-logo">
    <img src={fluxLogo} alt="Flux Logo" style={{ width: '100px', height: 'auto' }}/>

    </a>
</div>

          <div className="header__nav--right">
            <a href="#services" className="header__nav-link">
              How it works
            </a>
            <a href="#services" className="header__nav-link">
              Why FLUX?
            </a>
            <a href="#roadmap" className="header__nav-link">
              Roadmap
            </a>

            {/* <Link to="/dashboard/incio" className="primary-button">
              Conecta ahora
            </Link> */}

<Link className="primary-button" onClick={initConnection}>
             DAPP
            </Link>
          </div>
        </nav>
      </header>

      <main className="main">
        <section className="section__hero container">
          <div className="section__hero--left">
            <div className="section__hero--left-container">
              <h1 className="section__hero-title">
              The Ultimate <span>crypto</span> streaming hub
              </h1>
              <p className="section__hero-description">
              The pinnacle of entertainment. Every giant, one platform. Pay with crypto, experience the extraordinary. Why settle for less? #StreamWithCrypto.{" "}
              </p>
              <div className="section__hero--left-buttons">
                {/* <a
                  href="#"
                  className="primary-button section__hero--left-button"
                >
                  Empieza ahora
                </a> */}
                <a href="https://app.uniswap.org/" className="primary-button">
                  Buy now
                </a>
                <a href="#suscriptions" className="section__hero--left-button-secundary">
                  Dextools
                </a>
              </div>
            </div>
          </div>

          <div className="section__hero--right">
            <div className="section__hero--right-container">
              <img
                className="section__hero--right-img"
                src={require("./../../assets/img/heroheader.png")}
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="section__partner ">
          <div className="section__partner-container ">
            <img
              className="section__partner-img"
              src={require("./../../assets/img/YouTube-Logo.svg").default}
              alt=""
            />
            <img
              className="section__partner-img"
              src={require("./../../assets/img/Steam_2016_logo_black.svg").default}
              alt=""
            />
            <img
              className="section__partner-img"
              src={require("./../../assets/img/Netflix_2015_logo.svg").default}
              alt=""
            />
            <img
              className="section__partner-img"
              src={require("./../../assets/img/amazon.svg").default}
              alt=""
            />
            <img
              className="section__partner-img"
              src={require("./../../assets/img/hbo.svg").default}
              alt=""
            />
          </div>
        </section>

        <section className="section__services container" id="services">
          <h2 className="subtitle">How it works</h2>
          <div className="section__services-container">
            <div className="section__services-service-box">
              <div className="section__services-service-image-box">
                <img
                  className="section__services-service-image"
                  src={
                    require("./../../assets/img/./../../assets/img/./../../assets/img/undraw_transfer_money_re_6o1h.svg")
                      .default
                  }
                  alt=""
                />
              </div>

              <div className="section__services-service-text">
                <h3 className="section__services-service-text--tile">
                  Use crypto
                </h3>
                <p className="section__services-service-text--description">
                  Pick your streamers and pay monthly with crypto, no contract!{" "}
                </p>
              </div>
            </div>

            <div className="section__services-service-box">
              <div className="section__services-service-image-box">
                <img
                  className="section__services-service-image"
                  src={
                    require("./../../assets/img/./../../assets/img/undraw_home_cinema_l7yl.svg")
                      .default
                  }
                  alt=""
                />
              </div>
              <div className="section__services-service-text">
                <h3 className="section__services-service-text--tile">
                  Get watching
                </h3>
                <p className="section__services-service-text--description">
                  Use the dashboard to access your chosen streaming services.{" "}
                </p>
              </div>
            </div>

            <div className="section__services-service-box">
              <div className="section__services-service-image-box">
                <img
                  className="section__services-service-image"
                  src={require("./../../assets/img/group.svg").default}
                  alt=""
                />
              </div>
              <div className="section__services-service-text">
                <h3 className="section__services-service-text--tile">Share account</h3>
                <p className="section__services-service-text--description">
                  You can share your account with up to 2 other $Flux holders.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section__subscriptions container" id="suscriptions">
          <div className="section__subscriptions-container">
            <div className="section__subscriptions-top">
              <h2 className="subtitle section__subscriptions-title">
                All the best streaming platforms
              </h2>
              <p className="section__subscriptions-description">
              Flux Protocol is set to reshape the world of subscriptions, offering access to popular websites like Netflix, HBO, Steam, and more with cryptocurrency payments. But it doesn't stop there; we've introduced a groundbreaking revenue-sharing model that rewards $FLUX token holders with 40% of the profits generated from subscription fees.
              </p>
            </div>
            <div className="section__subscriptions-image-box">
              <img
                className="section__subscriptions-image"
                src={require("./../../assets/img/YouTube-Logo2.svg").default}
                alt=""
              />
              <img
                className="section__subscriptions-image"
                src={require("./../../assets/img/hbo2.svg").default}
                alt=""
              />
              <img
                className="section__subscriptions-image"
                src={require("./../../assets/img/Netflix_2015_logo.svg").default}
                alt=""
              />
              <img
                className="section__subscriptions-image"
                src={require("./../../assets/img/amazon.svg").default}
                alt=""
              />
              <img
                className="section__subscriptions-image"
                src={require("./../../assets/img/Steam_2016_logo_white.svg").default}
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="section__how container" id="how">
          <div className="section__how-container">
            <h2 className="subtitle section__how-title">
              Why choose Flux?
            </h2>
            <p className="section__how-description">
              We're the no1 crypto streaming subscription service, find out why.
            </p>
            <div className="section__how-container--grid">
              <div className="section__how-text-box">
                <div>
                  <h3 className="section__how-text--tile">
                    Decentralized
                  </h3>
                  <p className="section__how-how-text--description">
                  Crypto-Powered Payments: Pay for your favorite subscriptions using cryptocurrencies, making transactions secure, quick, and borderless.
                  </p>
                </div>
              </div>

              <div className="section__how-image-box">
                <img
                  className="section__how-image metamask-img"
                  src={require("./../../assets/img/decentralised.svg").default}
                  alt=""
                />
              </div>

              <div className="section__how-image-box">
                <img
                  className="section__how-image"
                  src={require("./../../assets/img/fluxscreen.svg").default}
                  alt=""
                />
              </div>

              <div className="section__how-text-box">
                <div>
                  <h3 className="section__how-text--tile">
                    No more restrictions
                  </h3>
                  <p className="section__how-how-text--description">
                  Decentralized Streaming: We're introducing a cutting-edge decentralized movie streaming platform. Say goodbye to location restrictions and privacy concerns. Enjoy a Netflix-like experience on a web3-based platform with enhanced privacy and no IP or location issues.
                  </p>
                </div>
              </div>

              <div className="section__how-text-box">
                <div>
                  <h3 className="section__how-text--tile">
                    Get your share
                  </h3>
                  <p className="section__how-how-text--description">
                  Revenue Share: Holders of the $FLUX token automatically become stakeholders in Flux Protocol's success. They receive 40% of the profit generated from subscription fees, creating a community-driven ecosystem.
                  </p>
                </div>
              </div>

              <div className="section__how-image-box">
                <img
                  className="section__how-image"
                  src={require("./../../assets/img/revshare.svg").default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        

        <section className="section__about container" id="roadmap">
          <div className="section__about-container">
            <div className="section__about-top">
              <h2 className="subtitle section__about-title">FLUX Hub</h2>
              <p className="section__about-description">
                FLUX hub will be the no1 crypto platform for more services as we expand. Check out our roadmap below.
              </p>
              <h3 className="roadmap-title">Roadmap</h3>
            </div>
            <div className="section__about-container-inner">
              <div className="roadmap-line"></div>
              <div className="section__about-container-inner-grid">
                <div className="section__about-container-inner-grid-item">
                  <div className="wrap align-right stage-text">
                    <p className="stage-text-title">Quarter 4 (2023): Token Establishment</p>
<ul>
                  <li>⚪️ Token Deployment</li>
                  <li>⚪️ Smart Contract Audit</li>
                  <li>⚪️ Launch Beta Testing</li>
                  <li>⚪️ Payment Integration</li>
    <li>⚪️ Exchange Listing Applications</li>
    <li>⚪️ Regulatory Compliance Research</li>
</ul>
                    
                  </div>
                  <div className="wrap flex-center ">
                    <div className="stage">Q4</div>
                  </div>
                </div>

                <div className="section__about-container-inner-grid-item">
                  <div className="wrap align-right"></div>
                  <div className="wrap flex-center ">
                    <div className="stage">Q1</div>
                  </div>
                  <div className="wrap stage-text">
                    <p className="stage-text-title">Quarter 1 (2024): User Adoption</p>
                    <ul><li>⚪️ Integrate with DApps</li>
                  <li>⚪️ Develop a decentralized streaming platform</li>
                  <li>⚪️ Token Staking Mechanism</li>
                  <li>⚪️ Community Engagement</li>
    <li>⚪️ Refine User Experience</li>
    <li>⚪️ Customer Support Setup</li>
    </ul>
                  </div>
                </div>

                <div className="section__about-container-inner-grid-item">
                  <div className="wrap align-right stage-text">
                    <p className="stage-text-title">Quarter 2 (2024): Market Expansion</p>
                    <ul><li>⚪️ Develop a decentralized movie ticketing platform</li>
          <li>⚪️ Implement Cross-Chain Bridge</li>
          <li>⚪️ Explore Layer 2 Scaling</li>
          <li>⚪️ Token Governance Voting</li>
          <li>⚪️ Global Marketing Campaign</li>
          <li>⚪️ Explore Liquidity Pools</li>
          </ul>
                  </div>
                  <div className="wrap flex-center ">
                    <div className="stage">Q2</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="footer-container container">
          <div>
            <a href="#" className="header__nav-logo">
              FLUX
            </a>
            <p className="footer-container-text">
            Flux Protocol is not just a subscription service; it's a revolution in how we access and enjoy content online. Join us as we redefine the future of subscriptions and streaming, all powered by the blockchain.
            </p>
          </div>
          <div className="footer-container-links"></div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Home;
