import React from "react";

import "./balance.css";
//src={require("./../../../../assets/img/grafica.png").default} className="img_grafica_balance"
const Balance = () => {
  return (
    <section className="section__balance-container">
      <h3 className="section__balance-item-title">Recently watched</h3>
      <div className="section__balance-item">
        
        <img
                className="img_grafica_balance"
                src="https://www.themoviedb.org/t/p/w1280/iuFNMS8U5cb6xfzi51Dbkovj7vM.jpg"
                alt=""
        />
       
      </div>
    </section>
  );
};

export default Balance;
