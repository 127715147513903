import React from "react";
import "./incio.css";

const Incio = () => {
  // Array of movie posters
  const moviePosters = [
    "https://www.themoviedb.org/t/p/w1280/mXLOHHc1Zeuwsl4xYKjKh2280oL.jpg",
    "https://www.themoviedb.org/t/p/w1280/4m1Au3YkjqsxF8iwQy0fPYSxE0h.jpg",
    "https://www.themoviedb.org/t/p/w1280/iuFNMS8U5cb6xfzi51Dbkovj7vM.jpg",
    "https://www.themoviedb.org/t/p/w1280/lCanGgsqF4xD2WA5NF8PWeT3IXd.jpg",
    "https://www.themoviedb.org/t/p/w1280/aAngiE34BMFDTOXpjc04Lr8zsX1.jpg",
    "https://www.themoviedb.org/t/p/w1280/4Y1WNkd88JXmGfhtWR7dmDAo1T2.jpg",
    "https://www.themoviedb.org/t/p/w1280/Af4bXE63pVsb2FtbW8uYIyPBadD.jpg",
    "https://www.themoviedb.org/t/p/w1280/gPbM0MK8CP8A174rmUwGsADNYKD.jpg",
    "https://www.themoviedb.org/t/p/w1280/rktDFPbfHfUbArZ6OOOKsXcv0Bm.jpg",
    "https://www.themoviedb.org/t/p/w1280/vZloFAK7NmvMGKE7VkF5UHaz0I.jpg",
      // You can replace this with another URL or add more
    // ... add paths to other movie posters here ...
  ];

  return (
    <section className="section__balance-container">
      <h2 className="section__balance-item-title">Latest relaeases</h2>
      <div className="section__balance-item">
        {moviePosters.map((poster, index) => (
          <img 
            key={index} 
            src={poster}
            alt={`Movie Poster ${index}`} 
            className="img_grafica_balance"
          />
        ))}
      </div>
    </section>
  );
};

export default Incio;
