import React from "react";
import "./transacciones.css";

const Transacciones = () => {
  return (
    <section className="section__transacciones-container">
        <h3 className="section__transacciones-item-title">Subscriptions</h3>
        
        
          
        
    </section>
  );
};

export default Transacciones;

